import React from "react";


function Alcor() {

    return (
        <div>

            
            <h1>Procedure checklist for Alcor patients will go here</h1>

            
        </div>
    );
}
export default Alcor;