import React from "react";


function SecondPage() {

    return (
        <div>

            
            <h1>secondPage</h1>

            
        </div>
    );
}
export default SecondPage;