import React from "react";


function TOS() {

    return (
        <div>

            
            <h1>TOS</h1>

            
        </div>
    );
}
export default TOS;