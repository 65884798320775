import React from "react";


function CI() {

    return (
        <div>


            <h1>Procedure checklist for CI patients will go here</h1>


        </div>
    );
}
export default CI;