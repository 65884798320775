import React from "react";

function AutoheartwatchSignup() {
    return (

                    <div className="col-auto  order-2 order-md-1">
                        <img className="img-responsive frontimg" src='./images/AutoheartwatchSignup.png' alt="AutoheartwatchSignup" ></img>
                    </div>
                    
    );
}

export default AutoheartwatchSignup;