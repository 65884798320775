export const lightTheme = {
    blackLightgray: 'black',
    secondaryBorder: '#ced4da',
    body: '#FFF',
    text: '#363537',
    background: '#FFF',
    hover: 'rgba(0,0,0,0.15)',
    recipetext: '#737373',
    divide: '#ebebeb',
    tableStyle: '#363537',
    borderStyle: '#ebebeb',
    timerBtns1: '#8b95a5',
    timerBtns2: '#8b95a5',
    cardBorder: 'rgba(0,0,0,0.125)',
    inputColor: 'white',
    categoryBadge: 'rgb(239,239,239)',
    badgeBorder: 'rgb(211,211,211)',
    badgeText: 'gray',
    badgeTextHover: 'white',
    badgeBgHover: 'lightgray',
    badgeBorderHover: 'lightgray',
    formBorder: 'rgb(206,212,218)',
    amountBtns: 'lightgray',
    amountBtnsHover: 'darkgray',
    inputTimerColor: '#EEE',
    inputTimerText: '#666',
    btnSubtle: '#555',
    placeHolder: '#888',
    tagColor: '#888',
    navBorder: '#dee2e6',
    textFill: 'black',
    googleColor: '#EEE',
    googleText: '#757575',
    MaxBlackOrWhite:'White',
    MinBlackOrWhite:'Black',
}

export const darkTheme = {
    blackLightgray: 'lightgray',
    secondaryBorder: 'lightgray',
    body: '#222',
    text: 'lightgray',
    background: '#333',
    hover: 'rgba(255,255,255,0.3)',
    recipeText: 'silver',
    divide: 'gray',
    tableStyle: 'lightgray',
    borderStyle: 'lightgray',
    timerBtns1: 'lightgray',
    timerBtns2: '#929292',
    cardBorder: 'gray',
    inputColor: '#444',
    categoryBadge: '#666',
    badgeBorder: 'gray',
    badgeText: 'darkgray',
    badgeTextHover: 'gray',
    badgeBgHover: 'silver',
    badgeBorderHover: 'darkgray',
    formBorder: 'lightgray',
    amountBtns: 'darkgray',
    amountBtnsHover: 'gray',
    inputTimerColor: '#333',
    inputTimerText: '#CCC',
    btnSubtle: '#CCC',
    placeHolder: '#999',
    tagColor: 'darkgray',
    navBorder: 'lightgray',
    textFill: '#CCC',
    googleColor: '#444',
    googleText: 'lightgray',
    MaxBlackOrWhite:'Black',
    MinBlackOrWhite: 'White',
}